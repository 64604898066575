import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import ToolTip from '@material-ui/core/ToolTip';
import MailIcon from '@material-ui/icons/MailOutline';
import github from './assets/github.png';
import lakes from './assets/snowy-lakes-2014.jpg';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundImage: `url(${lakes})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
  },
  flex: {
    flex: 1,
  },
  roomRight: {
    marginRight: theme.spacing(),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  colorInherit: {
    color: 'inherit',
  },
  hidden: {
    visibility: 'hidden',
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" elevation={0} color="transparent">
        <Toolbar>
          <Typography variant="h4" className={classes.hidden}>Tim Butorac</Typography>
          <div className={classes.flex} />
          <ToolTip title="GitHub">
            <IconButton
              className={classes.roomRight}
              component={Link}
              href="https://github.com/timbutokawa"
            >
              <Avatar
                alt="github-link"
                src={github}
                className={classes.small}
              />
            </IconButton>
          </ToolTip>
          <ToolTip title="timbutorac@gmail.com">
            <IconButton
              component={Link}
              href="mailto:timbutorac@gmail.com?subject=Contact from timbutorac.com"
              target="_blank"
              rel="noopener noreferrer"
              TypographyClasses={{
                root: classes.colorInherit
              }}
            >
              <MailIcon />
            </IconButton>
          </ToolTip>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default App;
